<template>
  <div>
    <b-modal
      @hidden="toggle360Selections"
      @ok="toggle360Selections"
      v-model="internalModalVisible"
      hide-footer
      no-close-on-backdrop
      centered
      size="lg"
      title="Select dv360 Advertisers"
    >
      <b-row v-if="this.isFetchingDv360Advertiser" class="spinnerCentered">
        <div>
          <multiChannelSpinner />
        </div>
      </b-row>
      <b-row
        class="center-text-no-found"
        v-else-if="this.dv360Accounts && this.dv360Accounts.length === 0"
      >
        <div>
          <p>No data found!</p>
        </div>
      </b-row>
      <b-row v-else>
        <template>
          <b-col cols="12">
            <b-card-text>
              <app-collapse type="border" class="mainContainer">
                <app-collapse-item
                  v-for="(customer, customerIndex) in dv360Accounts"
                  :key="customer.partnerId"
                  :title="`${customer.displayName}`"
                >
                  <div
                    v-if="
                      customer.advertiserAccounts &&
                        customer.advertiserAccounts.length > 0
                    "
                  >
                    <input
                      placeholder="search customer.."
                      class="search-option"
                      v-model="searchAdvertiser"
                    />
                    <b-list-group>
                      <b-list-group-item
                        v-for="(advertiser,
                        advertiserIndex) in paginatedAdvertisers(customer)"
                        :key="advertiser.advertiserId"
                      >
                        <div
                          class="d-flex align-items-center justify-content-between ml-1 d-inline"
                          style="margin-top: 8px;"
                        >
                          <div>
                            <h5>{{ advertiser.displayName }}</h5>
                            <h6>{{ advertiser.advertiserId }}</h6>
                          </div>
                          <div class="radio-container">
                            <b-form-checkbox
                              :checked="isSelected(advertiser.advertiserId)"
                              @change="
                                toggleDv360Selection(
                                  advertiser,
                                  advertiserIndex,
                                  advertiser.advertiserId,
                                  advertiser.displayName,
                                  advertiser.partnerId
                                )
                              "
                              :id="String(advertiserIndex)"
                            ></b-form-checkbox>
                          </div>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                    <div
                      class="pagination mt-2"
                      style="display: flex; justify-content: center; align-items: center; margin-top: 5px;"
                    >
                      <b-pagination
                        v-model="customer.currentPage"
                        :total-rows="pageLength"
                        :per-page="itemsPerPage"
                        align="center"
                      ></b-pagination>
                    </div>
                  </div>
                  <div v-else>
                    <p>No advertiser accounts available.</p>
                  </div>
                </app-collapse-item>
              </app-collapse>
            </b-card-text>
          </b-col>
        </template>
      </b-row>
      <b-row v-if="this.advertiserAccounts && this.advertiserAccounts.length > 0">
        <b-col cols="12">
          <div
            style="display:flex; justify-content:space-between; gap:10px;margin-top:5px;padding: 0px 8px !important;"
          >
            <b-button variant="outline-primary" class="mr-1" @click="closeModal"
              >Cancel</b-button
            >
            <b-button variant="primary" @click="saveDv360Data">Submit</b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BModal,
  BButton,
  BBadge,
  BCardText,
  BSpinner,
  BFormCheckbox,
  BListGroupItem,
  BListGroup,
  BPagination,
  BCol,
} from "bootstrap-vue";
import axios from "axios";
import { getUserToken } from "@/auth/utils";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  props: {
    dv360ModalToggle: {
      type: Boolean,
      default: true,
    },
    dv360Accounts: {
      type: Array,
      default: [],
    },
    fetchDataByClientId: {
      type: String,
    },
    // googleSelected: {
    //   type: Array,
    // },
    dv360SelectedIds: {
      type: Array,
    },
    dv360SelectedNames: {
      type: Array,
    },
    // googleSelecteds: {
    //   type: Array,
    // },
  },
  data() {
    return {
      dv360SelectedData: [],
      isFetchingDv360Advertiser: false,
      advertiserAccounts: [],
      dataform: {},
      localGoogleSelected: this.dv360SelectedIds,
      itemsPerPage: 20,
      userData: [],
      currentPage: 1,
      searchAdvertiser: "",
      internalModalVisible: this.dv360ModalToggle,
    };
  },
  components: {
    BCard,
    BRow,
    BModal,
    BButton,
    BBadge,
    AppCollapse,
    AppCollapseItem,
    BCardText,
    BSpinner,
    BFormCheckbox,
    BListGroupItem,
    BListGroup,
    BPagination,
    BCol,
    multiChannelSpinner,
  },
  watch: {
    googleSelected(newVal) {
      this.localGoogleSelected = newVal;
    },
    dv360ModalToggle(value) {
      this.fetchDv360Advertiser();
      this.internalModalVisible = value;
    },
  },
  computed: {
    totalGoogleRows() {
      return  this.advertiserAccounts && this.advertiserAccounts.advertiserAccounts && this.advertiserAccounts.advertiserAccounts.length;
    },
  },
  methods: {
    paginatedAdvertisers(customer) {
      const filteredAdvertisers = customer.advertiserAccounts.filter(
        (advertiser) =>
          advertiser.displayName
            .toLowerCase()
            .includes(this.searchAdvertiser.trim().toLowerCase())
      );

      // Apply pagination to the filtered results
      const start = (customer.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.pageLength = filteredAdvertisers.length;
      return filteredAdvertisers.slice(start, end);
    },
    toggleDv360Selection(
      advertiser,
      advertiserIndex,
      advertiserId,
      displayName,
      partnerId
    ) {
      // Ensure `dv360SelectedData` and `advertisers` are properly initialized
      if (!this.dv360SelectedData) {
        this.dv360SelectedData = { advertisers: [] };
      }
      if (!Array.isArray(this.dv360SelectedData.advertisers)) {
        this.dv360SelectedData.advertisers = [];
      }
      if (this.dv360SelectedIds && this.dv360SelectedIds.length > 0) {
        for (let i = 0; i < this.dv360SelectedIds.length; i++) {
          const existingId = this.dv360SelectedIds[i];
          const existingName = this.dv360SelectedNames[i];

          // Add the advertiser if not already in `dv360SelectedData.advertisers`
          const isExisting = this.dv360SelectedData.advertisers.some(
            (adv) => adv.advertiserId === existingId
          );

          if (!isExisting) {
            this.dv360SelectedData.advertisers.push({
              advertiserId: existingId,
              advertiserName: existingName,
              partnerId: partnerId, // Assuming all existing entries have the same partnerId
            });
          }
        }
      }
      // Find if the advertiser is already selected by matching advertiserId
      const existingIndex = this.dv360SelectedData.advertisers.findIndex(
        (selectedAdvertiser) => selectedAdvertiser.advertiserId === advertiserId
      );

      if (existingIndex === -1) {
        // Add advertiser if not already selected (select action)
        this.dv360SelectedData.advertisers.push({
          advertiserId,
          advertiserName: displayName,
          partnerId,
        });
      } else {
        this.dv360SelectedData.advertisers.splice(existingIndex, 1);
      }
    },
    fetchDv360Advertiser() {
      this.isFetchingDv360Advertiser = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/dv-360/get-advertisers`,
          {
            clientID: this.fetchDataByClientId,
            partnerId: this.dv360Accounts[0].partnerId,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.advertiserAccounts = response.data && response.data.advertisers;
          if (this.dv360Accounts[0]) {
            this.dv360Accounts[0].advertiserAccounts = [
              ...this.advertiserAccounts,
            ];
            if (!this.dv360Accounts[0].currentPage) {
              this.$set(this.dv360Accounts[0], "currentPage", 1);
            } else {
              this.dv360Accounts[0].currentPage = 1;
            }
          }
          this.isFetchingDv360Advertiser = false;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.isFetchingDv360Advertiser = false;
        });
    },
    closeModal() {
      this.internalModalVisible = false;
    },
    isSelected(client) {
      return (
        this.dv360SelectedIds &&
        this.dv360SelectedIds.some((selectedName) => selectedName === client || (this.dv360SelectedData &&
          Array.isArray(this.dv360SelectedData.advertisers) &&
          this.dv360SelectedData.advertisers.some(
            (advertiser) => advertiser.advertiserId === client
          )))
        );
    },
    saveDv360Data() {
      this.$emit("saveDv360Data", this.dv360SelectedData);
    },
    toggle360Selections() {
      this.$emit("toggle360Selections", false);
      setTimeout(() => {
        this.searchAdvertiser = "";
      }, 2000);
    },
    selectGoogleCustomerAccount(customerdData) {
      this.$emit("saveAdsCustomerID", customerdData);
    },
  },
};
</script>

<style lang="scss">
.card.strike-through .collapse-title {
  text-decoration: line-through;
}
.search-option {
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 10px;
  width: 100% !important;
  height: 38px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  color: rgb(163, 153, 153);
}
.mainContainer {
  width: 100%;
}
.justify-content-end {
  justify-content: center !important;
}
.center-text-no-found {
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}
.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
}
</style>
